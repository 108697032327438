import React from "react";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { vendorHeadCells } from "../../seed/table-headers";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import { CREATE, ROWSPERPAGE, SMAIVENDOR, UPDATE } from "../../utils/constant";
import { authPostRequest, getRequest, postRequest } from "../../services/api-service";
import {
  deleteAccountUrl,
  enableDisableUserUrl,
  getAllDistrictsUrl,
  getAllRegionsUrl,
  getAllUsersByRoleUrl,
  registerUserByRoleUrl,
  updateUserByRoleUrl,
} from "../../seed/url";
import { CustomAlert } from "../../components/custom-alert";
import { FormDialog } from "../../components/form-dialog";
import { vendorFormFields } from "../../seed/form-fields";
import { useSelector } from "react-redux";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";

const useContentsIds = (vendors) => {
  return React.useMemo(() => {
    return vendors.map((customer) => customer.id);
  }, [vendors]);
};

function Vendors() {
  const [action, setAction] = React.useState(CREATE);
  const formInfo = useSelector((state) => state.FormInformationReducer);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
  const [regions, setRegions] = React.useState([]);
  const [formFields, setFormFields] = React.useState(vendorFormFields);
  const [vendors, setVendors] = React.useState({
    page: 1,
    total_results: 0,
    total_pages: 0,
    results: [],
  });
  const [selectedData, setSelectedData] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const secretariesIds = useContentsIds(vendors.results);
  const secretariesSelection = useSelection(secretariesIds);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [severityMessage, setSeverityMessage] = React.useState("");
  const [isSubmitting, setSubmitting] = React.useState(false);
  const values = [
    {
      id: action === UPDATE ? selectedData.id : 0,
      username: action === UPDATE ? selectedData.userName : "",
      email: action === UPDATE ? selectedData.email : "",
      phone_no: action === UPDATE ? selectedData.phoneNumber : "",
      inbound_recharge_amount: action === UPDATE ? selectedData.inbound_recharge_amount : 0,
      outbound_recharge_amount: action === UPDATE ? selectedData.outbound_recharge_amount : 0,
      inbound_return_amount: action === UPDATE ? selectedData.inbound_return_amount : 0,
      outbound_return_amount: action === UPDATE ? selectedData.outbound_return_amount : 0,
      account_type: action === UPDATE ? selectedData.account_type : "",
      account_number: action === UPDATE ? selectedData.account_number : "",
      include_shipping_cost: action === UPDATE ? selectedData.include_shipping_cost : "",
      role: SMAIVENDOR,
    },
  ];
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetcher = React.useCallback(
    (page) => {
      setIsLoading(true);
      authPostRequest(
        getAllUsersByRoleUrl,
        {
          role: SMAIVENDOR,
          query: searchTerm,
          sort: orderBy + " " + order,
          limit: rowsPerPage,
          page: page,
        },
        (data) => {
          setVendors(data);
          setIsLoading(false);
        },
        (error) => {
          setVendors({
            page: 1,
            total_results: 0,
            total_pages: 0,
            results: [],
          });
          setIsLoading(false);
        }
      );
    },
    [rowsPerPage, searchTerm, orderBy, order]
  );

  const enableDisableUser = (data) => {
    if (!isSubmitting) {
      setSubmitting(true);
      secretariesSelection.handleSelectOne(data);
      authPostRequest(
        enableDisableUserUrl,
        {
          user_id: data.id,
          status: data.status === "ACTIVE" ? "DISABLED" : "ACTIVE",
        },
        (data) => {
          fetcher(vendors.page);
          setSubmitting(false);
        },
        (error) => {
          if (error?.response?.data?.message) {
            setSeverityMessage(error.response.data.message[0]);
            setSeverity("error");
            handleClickAlert();
          }
          setSubmitting(false);
        }
      );
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getAllDistricts = React.useCallback((regionId) => {
    setIsLoading(true)
    postRequest(
      getAllDistrictsUrl,
      {
        "id": regionId
      },
      (data) => {
        const newDistricts = data.map((category) => {
          const newItem = {};
          ["label", "value"].forEach((item) => {
            if (item === "label") {
              newItem[item] = category.district_name;
            }
            if (item === "value") {
              newItem[item] = category.id;
            }
          });
          return newItem;
        });
        let newFormFields = formFields;
        newFormFields[4].items = newDistricts;
        setFormFields(newFormFields);
        setIsLoading(false)
      },
      (error) => {
        let newFormFields = formFields;
        newFormFields[4].items = [];
        setFormFields(newFormFields);
        setIsLoading(false)
      },
    )
  }, [formFields])

  React.useEffect(() => {
    fetcher(1);
  }, [fetcher]);

  React.useEffect(() => {
    getRequest(
      getAllRegionsUrl,
      (data) => {
        setRegions(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [])

  React.useEffect(() => {
    if (formInfo.region_id) {
      getAllDistricts(formInfo?.region_id)
    }
  }, [formInfo, getAllDistricts])

  React.useEffect(() => {
    if (regions.length > 0) {
      const newRegions = regions.map((region) => {
        const newItem = {};
        ["label", "value", "id"].forEach((item) => {
          if (item === "label") {
            newItem[item] = region.region_name;
          }
          if (item === "value") {
            newItem[item] = region.id;
          }
        });
        return newItem;
      });
      let newFormFields = formFields;
      newFormFields[3].items = newRegions;
      setFormFields(newFormFields);
    }
  }, [regions, formFields])

  const handlePageChange = React.useCallback(
    (event, value) => {
      fetcher(value + 1);
    },
    [fetcher]
  );

  const handleRowsPerPageChange = React.useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    action === UPDATE ? fetcher(vendors.page) : fetcher(1);
    setOpenCreateDialog(false);
    setAction(CREATE);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const onSelect = (data, openDialog) => {
    setSelectedData(data);
  };

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    authPostRequest(
      deleteAccountUrl,
      {
        user_id: selectedData.id,
      },
      (data) => {
        fetcher(vendors.page);
        setSeverityMessage(data.message);
        setSeverity("success");
        handleClickAlert();
        setIsDeleting(false);
        handleCloseDeleteDialog();
      },
      (error) => {
        if (error?.response?.data?.message) {
          setSeverityMessage(error.response.data.message[0]);
          setSeverity("error");
          handleClickAlert();
        }
        setIsDeleting(false);
      }
    );
  };

  const contentPopoverItems = [
    {
      id: "edit",
      label: "Edit",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <PencilIcon />
        </SvgIcon>
      ),
      onClick: () => {
        setAction(UPDATE);
        handleClickOpenCreateDialog();
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <TrashIcon />
        </SvgIcon>
      ),
      onClick: () => {
        handleClickOpenDeleteDialog();
      },
    },
  ];

  return (
    <>
      {openAlert && (
        <CustomAlert
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          severity={severity}
          severityMessage={severityMessage}
        />
      )}
      {openCreateDialog && (
        <FormDialog
          open={openCreateDialog}
          handleClose={handleCloseCreateDialog}
          dialogTitle={"Vendor"}
          action={action}
          fields={vendorFormFields}
          values={values}
          url={action === CREATE ? registerUserByRoleUrl : updateUserByRoleUrl}
        />
      )}
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleAction={handleDelete}
          isPerformingAction={isDeleting}
          dialogTitle={"Delete Alert"}
          dialogContentText={"Are you sure you want to delete this item?"}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 2,
          pb: 8,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Vendors</Typography>
              </Stack>
              <div>
                <Button
                  onClick={handleClickOpenCreateDialog}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{
                    color: "neutral.100",
                  }}
                >
                  Add
                </Button>
              </div>
            </Stack>
            <CustomSearch handleSearch={handleSearch} />
            <CustomTable
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              count={vendors.total_results}
              items={vendors.results}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSelectOne={secretariesSelection.handleSelectOne}
              onSelect={onSelect}
              page={
                vendors.page >= 1 ? vendors.page - 1 : vendors.page
              }
              rowsPerPage={rowsPerPage}
              selected={secretariesSelection.selected}
              headCells={vendorHeadCells}
              popoverItems={contentPopoverItems}
              isLoading={isLoading}
              switchFunction={enableDisableUser}
              isSubmitting={isSubmitting}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Vendors;
