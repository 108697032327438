import { SvgIcon } from "@mui/material";
import ChartBarIcon from "@heroicons/react/24/outline/ChartBarIcon";
import CreditCardIcon from "@heroicons/react/24/outline/CreditCardIcon";
import ShoppingCartIcon from "@heroicons/react/24/outline/ShoppingCartIcon";
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/outline/ClipboardDocumentListIcon";
import BuildingOffice2Icon from "@heroicons/react/24/outline/BuildingOffice2Icon";
import PrinterIcon from "@heroicons/react/24/outline/PrinterIcon";
import { ADMIN, SMAIADMIN, SMAICASHIER, SMAIDISPATCHMANAGER, SMAIINVENTORYMANAGER, SMAISECRETARY, SMAISUPERVISOR, SMAIVENDOR, VENDOR } from "../../utils/constant";

export const items = [
  {
    title: "Home",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
    roles: [
      ADMIN,
      SMAIADMIN,
      SMAISECRETARY,
      SMAICASHIER,
      SMAIVENDOR,
      SMAIDISPATCHMANAGER,
      SMAIINVENTORYMANAGER,
      SMAISUPERVISOR,
      VENDOR,
    ],
  },
  {
    title: "Orders",
    path: "/orders/orders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingCartIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Online Orders",
        path: "/orders/orders",
        roles: [
          ADMIN,
          SMAIADMIN,
          VENDOR,
        ],
      },
      {
        title: "Offline Orders",
        path: "/orders/imported-orders",
        roles: [
          ADMIN,
          SMAIADMIN,
          SMAISECRETARY,
          SMAICASHIER,
          SMAIDISPATCHMANAGER,
          SMAIINVENTORYMANAGER,
          SMAISUPERVISOR,
          VENDOR,
        ],
      },
    ],
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: (
      <SvgIcon fontSize="small">
        <BuildingOffice2Icon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Products",
        path: "/inventory",
        roles: [
          ADMIN,
          SMAIADMIN,
          SMAIINVENTORYMANAGER,
          SMAISUPERVISOR,
          VENDOR,
        ],
      },
      {
        title: "Expenses",
        path: "/expenses",
        roles: [
          ADMIN,
          SMAIADMIN,
          SMAISUPERVISOR,
          SMAICASHIER
        ],
      },
    ],
  },
  {
    title: "Payments",
    path: "/payments/verification-orders-from-app",
    icon: (
      <SvgIcon fontSize="small">
        <CreditCardIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Online Orders",
        path: "/payments/verification-orders-from-app",
        roles: [
          ADMIN,
          SMAIADMIN,
          VENDOR,
        ],
      },
      {
        title: "Offline Orders",
        path: "/payments/verification-orders-from-vendors",
        roles: [
          ADMIN,
          SMAIADMIN,
          VENDOR,
          SMAISUPERVISOR,
          SMAICASHIER
        ],
      },
    ],
  },
  {
    title: "Printing",
    path: "/printing/orders-from-app",
    icon: (
      <SvgIcon fontSize="small">
        <PrinterIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Online Orders",
        path: "/printing/orders-from-app",
        roles: [
          ADMIN,
          SMAIADMIN,
          VENDOR,
        ],
      },
      {
        title: "Offline Orders",
        path: "/printing/orders-from-vendors",
        roles: [
          ADMIN,
          SMAIADMIN,
          VENDOR,
          SMAISUPERVISOR,
          SMAIDISPATCHMANAGER
        ],
      },
    ],
  },
  {
    title: "Invoices",
    path: "/invoices/orders-from-app",
    icon: (
      <SvgIcon fontSize="small">
        <ClipboardDocumentListIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Online Orders",
        path: "/invoices/orders-from-app",
        roles: [
          ADMIN,
          SMAIADMIN,
        ],
      },
      {
        title: "Offline Orders",
        path: "/invoices/orders-from-vendors",
        roles: [
          ADMIN,
          SMAIADMIN,
          SMAISUPERVISOR,
          SMAICASHIER,
        ],
      },
    ],
  },
  {
    title: "Users",
    path: "/users/vendors",
    icon: (
      <SvgIcon fontSize="small">
        <UserGroupIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Vendors",
        path: "/users/vendors",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Confirming Agents",
        path: "/users/confirming-agents",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Dispatch Managers",
        path: "/users/dispatch-managers",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Finance Agents",
        path: "/users/cashier-agents",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Inventory Managers",
        path: "/users/inventory-managers",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Supervisors",
        path: "/users/supervisors",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
      {
        title: "Administrators",
        path: "/users/administrators",
        roles: [
          ADMIN,
          SMAIADMIN
        ],
      },
    ],
  },
  {
    title: "Inventory",
    path: "/vendor/inventory",
    icon: (
      <SvgIcon fontSize="small">
        <BuildingOffice2Icon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Products",
        path: "/vendor/inventory",
        roles: [
          SMAIVENDOR
        ],
      },
    ],
  },
  {
    title: "Orders",
    path: "/vendor/orders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingCartIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Orders",
        path: "/vendor/orders",
        roles: [
          SMAIVENDOR
        ],
      },
    ],
  },
  {
    title: "Invoices",
    path: "/vendor/invoices",
    icon: (
      <SvgIcon fontSize="small">
        <ClipboardDocumentListIcon />
      </SvgIcon>
    ),
    children: [
      {
        title: "Invoices",
        path: "/vendor/invoices",
        roles: [
          SMAIVENDOR
        ],
      },
    ],
  },
];
