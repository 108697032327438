import React from 'react'
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useSelection } from '../../hooks/use-selection';
import { CustomTable } from '../../components/custom-table';
import { CustomSearch } from '../../components/custom-search';
import { productsHeadCells } from '../../seed/table-headers';
import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import CameraIcon from '@heroicons/react/24/outline/CameraIcon';
import VideoCameraIcon from '@heroicons/react/24/outline/VideoCameraIcon';
import { CREATE, ROWSPERPAGE, UPDATE, filterItems, productStatus } from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';
import PencilIcon from '@heroicons/react/24/outline/PencilIcon';
import { FormDialog } from '../../components/form-dialog';
import { productYoutubeVideoFields, productsFormFields } from '../../seed/form-fields';
import { postRequest } from '../../services/api-service';
import { addProductYouTubeVideoUrl, createProductUrl, deleteProductUrl, getAllProductsUrl, updateProductUrl } from '../../seed/url';
import { CustomAlert } from '../../components/custom-alert';
import { Scrollbar } from '../../components/scrollbar';
import { ConfirmationDialog } from '../../components/confirmation-dialog';
import ViewProductMedia from './ViewProductMedia';

const useProductsIds = (specializations) => {
    return React.useMemo(
        () => {
            return specializations.map((customer) => customer.id);
        },
        [specializations]
    );
};

function Inventory() {
    const dispatch = useDispatch();
    const productSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
    const [currentTab, setCurrentTab] = React.useState(0);
    const [action, setAction] = React.useState(CREATE)
    const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
    const [products, setProducts] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: []
    });
    const [selectedData, setSelectedData] = React.useState({})
    const [searchTerm, setSearchTerm] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDeleting, setIsDeleting] = React.useState(false)
    const productsIds = useProductsIds(products.results);
    const productsSelection = useSelection(productsIds);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openViewProductMediaDialog, setOpenViewProductMediaDialog] = React.useState(false);
    const [openViewProductYoutubeVideoDialog, setOpenViewProductYoutubeVideoDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false)
    const [severity, setSeverity] = React.useState("")
    const [severityMessage, setSeverityMessage] = React.useState("")
    const values = [
        {
            id: action === UPDATE ? selectedData.id : 0,
            product_name: action === UPDATE ? selectedData.product_name : "",
            product_vendor_id: action === UPDATE ? selectedData.product_vendor_id : 0,
            product_quantity: action === UPDATE ? selectedData.product_quantity : 0,
            product_amount: action === UPDATE ? selectedData.product_amount : 0,
            product_shipping_cost_in_dar: action === UPDATE ? selectedData.product_shipping_cost_in_dar : 0,
            product_shipping_cost_in_other_regions: action === UPDATE ? selectedData.product_shipping_cost_in_other_regions : 0,
            multiply_shipping_cost: action === UPDATE ? selectedData.multiply_shipping_cost : "",
        }
    ]
    const productYoutubeVideoValues = [{
        product_id: selectedData.id,
        image_path: "",
        thumbunail_url: "",
        file_type: ""
    }]
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true)
            postRequest(
                getAllProductsUrl,
                {
                    query: searchTerm,
                    status: currentTab === 0 ? "ALL" : currentTab === 1 ? "AVAILABLE" : "UNAVAILABLE",
                    sort: orderBy + " " + order,
                    limit: rowsPerPage,
                    page: page
                },
                (data) => {
                    setProducts(data)
                    setIsLoading(false)
                },
                (error) => {
                    setProducts({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    })
                    setIsLoading(false)
                },
            )
        },
        [rowsPerPage, searchTerm, currentTab, orderBy, order]
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
    }

    React.useEffect(() => {
        fetcher(1)
    }, [fetcher])

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1)
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback(
        (event) => {
            setRowsPerPage(event.target.value);
        },
        []
    );

    const handleClickOpenCreateDialog = () => {
        setOpenCreateDialog(true)
    }

    const handleCloseCreateDialog = () => {
        action === UPDATE ? fetcher(products.page) : fetcher(1)
        setOpenCreateDialog(false)
        setAction(CREATE)
    }

    const handleClickOpenViewProductMediaDialog = () => {
        setOpenViewProductMediaDialog(true)
    }

    const handleCloseViewProductMediaDialog = () => {
        setOpenViewProductMediaDialog(false)
    }

    const handleClickOpenViewProductYoutubeVideoDialog = () => {
        setOpenViewProductYoutubeVideoDialog(true)
    }

    const handleCloseViewProductYoutubeVideoDialog = () => {
        setOpenViewProductYoutubeVideoDialog(false)
    }

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true)
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }

    const handleClickAlert = () => {
        setOpenAlert(true)
    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenAlert(false)
    }

    const handleDelete = async () => {
        postRequest(
            deleteProductUrl,
            {
                id: selectedData.id,
            },
            setIsDeleting,
            (data) => {
                fetcher(products.page)
                setSeverityMessage(data.message)
                setSeverity("success")
                handleClickAlert()
                setIsDeleting(false)
                handleCloseDeleteDialog()
            },
            (error) => {
                if (error?.response?.data?.message) {
                    setSeverityMessage(error.response.data.message[0])
                    setSeverity("error")
                    handleClickAlert()
                }
                setIsDeleting(false)
            },
        )
    }

    const onSelect = (data, openDialog) => {
        setSelectedData(data)
    }

    const productsPopoverItems = [
        {
            id: 'view',
            label: 'View',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><EyeIcon /></SvgIcon>,
            onClick: () => {
                dispatch({
                    type: "TOOGLE_PAYMENT_SIDENAV",
                    payload: {
                        ...productSideNav,
                        openViewProductSideNav: true,
                        productSideNavContent: selectedData
                    },
                });
            },
        },
        {
            id: 'media_file',
            label: 'Media File',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><CameraIcon /></SvgIcon>,
            onClick: () => {
                handleClickOpenViewProductMediaDialog()
            },
        },
        {
            id: 'media_url',
            label: 'Media URL',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><VideoCameraIcon /></SvgIcon>,
            onClick: () => {
                handleClickOpenViewProductYoutubeVideoDialog()
            },
        },
        {
            id: 'edit',
            label: 'Edit',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><PencilIcon /></SvgIcon>,
            onClick: () => {
                setAction(UPDATE)
                handleClickOpenCreateDialog()
            },
        },
        {
            id: 'delete',
            label: 'Delete',
            icon: <SvgIcon fontSize="small" sx={{ color: "text.primary" }}><TrashIcon /></SvgIcon>,
            onClick: () => {
                handleClickOpenDeleteDialog()
            },
        },
    ]

    return (
        <>
            {openAlert &&
                <CustomAlert
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                    severity={severity}
                    severityMessage={severityMessage}
                />
            }
            {openCreateDialog &&
                <FormDialog
                    open={openCreateDialog}
                    handleClose={handleCloseCreateDialog}
                    dialogTitle={"Product"}
                    action={action}
                    fields={productsFormFields}
                    values={values}
                    url={action === CREATE ? createProductUrl : updateProductUrl}
                />
            }
            {openViewProductMediaDialog &&
                <ViewProductMedia
                    open={openViewProductMediaDialog}
                    handleClose={handleCloseViewProductMediaDialog}
                    selected={selectedData}
                />
            }
            {openViewProductYoutubeVideoDialog &&
                <FormDialog
                    open={openViewProductYoutubeVideoDialog}
                    handleClose={handleCloseViewProductYoutubeVideoDialog}
                    dialogTitle={"Product Media URL"}
                    action={action}
                    fields={productYoutubeVideoFields}
                    values={productYoutubeVideoValues}
                    url={addProductYouTubeVideoUrl}
                />
            }
            {openDeleteDialog &&
                <ConfirmationDialog
                    open={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    handleAction={handleDelete}
                    isPerformingAction={isDeleting}
                    dialogTitle={"Delete Alert"}
                    dialogContentText={"Are you sure you want to delete this item?"}
                />
            }
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pt: 2,
                    pb: 8
                }}
            >
                <Container maxWidth={false}>
                    <Stack spacing={2}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Typography variant="h4">
                                    Products
                                </Typography>
                            </Stack>
                            <div>
                                <Button
                                    onClick={handleClickOpenCreateDialog}
                                    startIcon={(
                                        <SvgIcon fontSize="small">
                                            <PlusIcon />
                                        </SvgIcon>
                                    )}
                                    variant="contained"
                                    sx={{
                                        color: "neutral.100"
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </Stack>
                        <Scrollbar
                            sx={{
                                position: 'sticky',
                                top: 64,
                                zIndex: (theme) => theme.zIndex.appBar
                            }}
                        >
                            <Box
                                component="header"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    alignContent: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "neutral.100",
                                    borderRadius: "70px",
                                    height: 75,
                                    minWidth: 1000,
                                    transition: 'all 0.3 ease-in-out',
                                }}
                            >
                                {productStatus.map((item, index) => {

                                    return (
                                        <Button
                                            key={index}
                                            sx={{
                                                borderRadius: "70px",
                                                width: '100%',
                                                height: '100%',
                                                color: 'text.primary',
                                                '&:hover': {
                                                    backgroundColor: currentTab === index ? 'primary.main' : 'neutral.300)'
                                                },
                                                ...(currentTab === index && {
                                                    backgroundColor: "primary.main",
                                                    color: "neutral.100",
                                                }),
                                            }}
                                            onClick={() => setCurrentTab(index)}
                                        >
                                            {item.label}
                                        </Button>
                                    );
                                })}
                            </Box>
                        </Scrollbar>
                        <CustomSearch
                            popoverItems={filterItems}
                            handleSearch={handleSearch}
                        />
                        <CustomTable
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            count={products.total_results}
                            items={products.results}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            onSelectOne={productsSelection.handleSelectOne}
                            onSelect={onSelect}
                            page={products.page >= 1 ? products.page - 1 : products.page}
                            rowsPerPage={rowsPerPage}
                            selected={productsSelection.selected}
                            headCells={productsHeadCells}
                            popoverItems={productsPopoverItems}
                            isLoading={isLoading}
                        />
                    </Stack>
                </Container>
            </Box>
        </>
    )
}

export default Inventory