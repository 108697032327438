import React from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { CustomTabs } from "../../components/CustomTabs";
import { ordersHeadCells } from "../../seed/table-headers";
import {
  allTimeTimeRange,
  convertArrayOfProductToString,
  formatMoney,
  lastSevenDaysTimeRange,
  ROWSPERPAGE,
  thisMonthTimeRange,
  todayTimeRange,
  yesterdayTimeRange,
} from "../../utils/constant";
import { getRequest, postRequest } from "../../services/api-service";
import {
  createOrderInvoiceUrl,
  getAllOrdersByPaginationUrl,
  getProductsOrdersInvoicesStatisticsUrl,
} from "../../seed/url";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import { useDispatch, useSelector } from "react-redux";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import ViewOrder from "./ViewOrder";
import { Scrollbar } from "../../components/scrollbar";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import ViewOrdersInvoices from "./ViewOrdersInvoices";
import { formatDateForExcel } from "../../utils/date-formatter";
import { useAuth } from "../../hooks/use-auth";

const useProductsOrdersIds = (orders) => {
  return React.useMemo(() => {
    return orders.map((customer) => customer.id);
  }, [orders]);
};

export const handleExport = (data) => {
  if (data.length > 0) {
    const newData = data.map((row, index) => {
      const newRow = {
        "S/No": index + 1,
        "Order ID": row?.order_id,
        "Full Name": row?.full_name,
        Region: row?.region,
        District: row?.district,
        Street: row?.street,
        "Phone Number": row?.phone_number,
        "Products": convertArrayOfProductToString(row?.products),
        "Order Status": row?.order_status,
        "Payment Status": row?.payment_status,
        "Product Amount": row?.product_amount,
        "Shipping Cost": row?.shipping_cost,
        "Date": formatDateForExcel(row?.created_at),
      };
      return newRow;
    });
    data = newData;
    let headings = Object.keys(data[0]);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [headings]);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Orders Invoices");
    writeFile(
      wb,
      `orders invoices downloaded at ${dayjs().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.xlsx`
    );
  }
};

function OrdersInvoices() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [statistics, setStatistics] = React.useState({
    total_products_cost_cash_on_delivery: 0,
    total_products_cost_cash_online_pending: 0,
    total_shipping_cost_cash_online_completed: 0,
    total_shipping_cost_cash_on_delivery: 0,
    total_shipping_cost_cash_online_pending: 0,
    total_returned_shipping_cost: 0
  });
  const totalProductsCost = (statistics.total_products_cost_cash_on_delivery + statistics.total_products_cost_cash_online_pending) -
    (statistics.total_shipping_cost_cash_online_completed + statistics.total_shipping_cost_cash_on_delivery + statistics.total_shipping_cost_cash_online_pending + statistics.total_returned_shipping_cost)
  const [currentTab, setCurrentTab] = React.useState("Statistics");
  const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
  const [openViewDialog, setOpenViewDialog] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
  const [productsOrders, setProductsOrders] = React.useState({
    page: 1,
    total_results: 0,
    total_pages: 0,
    results: [],
  });
  const [selectedData, setSelectedData] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [exportExcel, setExportExcel] = React.useState(false);
  const productsIds = useProductsOrdersIds(productsOrders.results);
  const productsOrdersSelection = useSelection(productsIds);
  const [openInvoiceDialog, setOpenInvoiceDialog] = React.useState(false);
  const [timeRange, setTimeRange] = React.useState({
    from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
    to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
  });
  const filterItems = [
    {
      label: "Today",
      onClick: () => {
        setTimeRange(todayTimeRange);
      },
    },
    {
      label: "Yesterday",
      onClick: () => {
        setTimeRange(yesterdayTimeRange);
      },
    },
    {
      label: "Last 7 days",
      onClick: () => {
        setTimeRange(lastSevenDaysTimeRange);
      },
    },
    {
      label: "This month",
      onClick: () => {
        setTimeRange(thisMonthTimeRange);
      },
    },
    {
      label: "All time",
      onClick: () => {
        setTimeRange(allTimeTimeRange);
      },
    },
  ];
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getDataForExportExcel = () => {
    setExportExcel(true);
    postRequest(
      getAllOrdersByPaginationUrl,
      {
        query: searchTerm,
        status: "DELIVERED",
        has_invoice: "NO",
        from: timeRange.from,
        to: timeRange.to,
        sort: orderBy + " " + order,
        limit: productsOrders.total_results,
        page: 1,
      },
      (data) => {
        handleExport(data?.results);
        setExportExcel(false);
      },
      (error) => {
        setExportExcel(false);
      }
    );
  };

  const fetcher = React.useCallback(
    (page) => {
      setIsLoading(true);
      postRequest(
        getAllOrdersByPaginationUrl,
        {
          query: searchTerm,
          status: "DELIVERED",
          has_invoice: "NO",
          from: timeRange.from,
          to: timeRange.to,
          sort: orderBy + " " + order,
          limit: rowsPerPage,
          page: page,
        },
        (data) => {
          setProductsOrders(data);
          setIsLoading(false);
        },
        (error) => {
          setProductsOrders({
            page: 1,
            total_results: 0,
            total_pages: 0,
            results: [],
          });
          setIsLoading(false);
        }
      );
    },
    [rowsPerPage, searchTerm, timeRange, orderBy, order]
  );

  const getProductsOrdersStatistics = React.useCallback(() => {
    setIsLoading(true);
    getRequest(
      getProductsOrdersInvoicesStatisticsUrl,
      (data) => {
        setStatistics(data);
        fetcher(1);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [setStatistics, fetcher]);

  const createProductOrderInvoice = () => {
    setIsSubmitting(true);
    postRequest(
      createOrderInvoiceUrl,
      {
        user_id: auth?.user?.id
      },
      (data) => {
        getProductsOrdersStatistics()
        fetcher(1)
        setIsSubmitting(false);
        handleCloseInvoiceDialog();
      },
      (error) => {
        getProductsOrdersStatistics()
        fetcher(productsOrders.page)
        setIsSubmitting(false);
      },
    )
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    getProductsOrdersStatistics();
  }, [getProductsOrdersStatistics]);

  const handlePageChange = React.useCallback(
    (event, value) => {
      fetcher(value + 1);
    },
    [fetcher]
  );

  const handleRowsPerPageChange = React.useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleClickOpenInvoiceDialog = () => {
    setOpenInvoiceDialog(true);
  };

  const handleCloseInvoiceDialog = () => {
    fetcher(1);
    setOpenInvoiceDialog(false);
  };

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    fetcher(productsOrders.page);
    setOpenViewDialog(false);
  };

  const onSelect = (data, openDialog) => {
    setSelectedData(data);
    openDialog && handleClickOpenViewDialog();
  };

  const orderPopoverItems = [
    {
      id: "track_order",
      label: "Track Order",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <EyeIcon />
        </SvgIcon>
      ),
      onClick: () => {
        dispatch({
          type: "TOOGLE_PAYMENT_SIDENAV",
          payload: {
            ...orderSideNav,
            openViewOrderStatusTrackingSideNav: true,
            orderStatusTrackingSideNavContent: selectedData,
          },
        });
      },
    },
  ];

  return (
    <>
      {openInvoiceDialog && (
        <ConfirmationDialog
          open={openInvoiceDialog}
          handleClose={handleCloseInvoiceDialog}
          handleAction={createProductOrderInvoice}
          isPerformingAction={isSubmitting}
          dialogTitle={"Confirmation Alert"}
          dialogContentText={"Are you sure you want to create invoice of " + formatMoney(totalProductsCost) + "?"}
        />
      )}
      {openViewDialog && (
        <ViewOrder
          open={openViewDialog}
          handleClose={handleCloseViewDialog}
          data={selectedData}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 2,
          pb: 8,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Invoices</Typography>
              </Stack>
              <div>
                <Button
                  onClick={handleClickOpenInvoiceDialog}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <DocumentTextIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{
                    color: "neutral.100",
                    m: 1,
                  }}
                >
                  Create Invoice
                </Button>
              </div>
            </Stack>
            <CustomTabs
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabsData={[
                { value: "Statistics", label: "Statistics" },
                { value: "Invoices", label: "Invoices" }
              ]}
              style={{}}
              indicatorColor={"primary"}
            />
            {currentTab === "Statistics" &&
              <>
                <Scrollbar>
                  <Card variant="outlined" sx={{ width: 600 }}>
                    <Table
                      sx={{
                        "& th, & td": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Products Cost Cash On Delivery
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_products_cost_cash_on_delivery)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Products Cost Cash Online Pending
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_products_cost_cash_online_pending)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Shipping Cost Cash Online Completed
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_shipping_cost_cash_online_completed)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Shipping Cost Cash On Delivery
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_shipping_cost_cash_on_delivery)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Shipping Cost Cash Online Pending
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_shipping_cost_cash_online_pending)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Returned Shipping Cost
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{formatMoney(statistics.total_returned_shipping_cost)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Products Cost
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{formatMoney(totalProductsCost)}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Total Count Orders
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography variant="subtitle1">{productsOrders.total_results}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Scrollbar>
                <CustomSearch
                  popoverItems={filterItems}
                  handleSearch={handleSearch}
                  exportExcel={exportExcel}
                  getDataForExportExcel={getDataForExportExcel}
                  selectedItems={productsOrdersSelection}
                />
                <CustomTable
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  count={productsOrders.total_results}
                  items={productsOrders.results}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onSelectOne={productsOrdersSelection.handleSelectOne}
                  onSelect={onSelect}
                  onDeselectOne={productsOrdersSelection.handleDeselectOne}
                  onDeselectAll={productsOrdersSelection.handleDeselectAll}
                  page={
                    productsOrders.page >= 1
                      ? productsOrders.page - 1
                      : productsOrders.page
                  }
                  rowsPerPage={rowsPerPage}
                  selected={productsOrdersSelection.selected}
                  headCells={ordersHeadCells}
                  popoverItems={orderPopoverItems}
                  isLoading={isLoading}
                />
              </>
            }
            {currentTab === "Invoices" &&
              <ViewOrdersInvoices />
            }
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default OrdersInvoices;
