import React from 'react'
import { Box, Button, Container, Grid, Skeleton, SvgIcon, Typography } from '@mui/material'
import ShoppingCartIcon from '@heroicons/react/24/outline/ShoppingCartIcon';
import HandThumbUpIcon from '@heroicons/react/24/outline/HandThumbUpIcon';
import TruckIcon from '@heroicons/react/24/outline/TruckIcon';
import AdjustmentsVerticalIcon from '@heroicons/react/24/outline/AdjustmentsVerticalIcon';
import ArrowUturnLeftIcon from '@heroicons/react/24/outline/ArrowUturnLeftIcon';
import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import CogIcon from '@heroicons/react/24/outline/CogIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../../services/api-service';
import { getExternalProductsOrdersStatisticsUrl, getExternalProductsOrdersTopRegionsUrl, getProductsOrdersStatisticsUrl, getProductsOrdersTopRegionsUrl } from '../../seed/url';
import Layout from '../../layouts/dashboard/Layout';
import { CustomTabs } from '../../components/CustomTabs';
import { formatMoney, getPercenteage, ordersBasedOnVendors } from '../../utils/constant';
import dayjs from 'dayjs';
import { CustomPopOver } from '../../components/custom-popover';
import { usePopover } from '../../hooks/use-popover';
import TopRegionsBarChart from '../VendorHome/TopRegionsBarChart';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import ThemeCustomization from '../../themes';

function Home() {
  const popOver = usePopover();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true)
  const [dashboardData, setDashboardData] = React.useState([]);
  const [topRegions, setTopRegions] = React.useState([]);
  const [isLoadingTopRegions, setIsLoadingTopRegions] = React.useState(true);
  const [currentTab, setCurrentTab] = React.useState(ordersBasedOnVendors[0].value);
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day'),
    "to": dayjs(),
  });

  const handleBodyChange = (newValue, key) => {
    setBody({ ...body, [key]: newValue, })
  }

  const getProductsOrdersStatistics = React.useCallback(
    () => {
      setIsLoading(true)
      postRequest(
        currentTab === ordersBasedOnVendors[0].value ?
          getProductsOrdersStatisticsUrl :
          getExternalProductsOrdersStatisticsUrl,
        {
          from: body.from.format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: body.to.format('YYYY-MM-DD HH:mm:ss.SSS'),
        },
        (data) => {
          setDashboardData(
            [
              {
                name: "Total Orders",
                value: data?.total_orders,
                percentage: getPercenteage(data?.total_orders, data?.total_orders),
                productCost: data?.total_orders_product_cost,
                shippingCost: data?.total_orders_shipping_cost,
                icon: <ShoppingCartIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Pending Orders",
                value: data?.pending_orders,
                percentage: getPercenteage(data?.pending_orders, data?.total_orders),
                productCost: data?.pending_orders_product_cost,
                shippingCost: data?.pending_orders_shipping_cost,
                icon: <AdjustmentsVerticalIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Need To Call Orders",
                value: data?.need_to_call_orders,
                percentage: getPercenteage(data?.need_to_call_orders, data?.total_orders),
                productCost: data?.need_to_call_orders_product_cost,
                shippingCost: data?.need_to_call_orders_shipping_cost,
                icon: <PhoneIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Confirmed Orders",
                value: data?.confirmed_orders,
                percentage: getPercenteage(data?.confirmed_orders, data?.total_orders),
                productCost: data?.confirmed_orders_product_cost,
                shippingCost: data?.confirmed_orders_shipping_cost,
                icon: <HandThumbUpIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Processing Orders",
                value: data?.processing_orders,
                percentage: getPercenteage(data?.processing_orders, data?.total_orders),
                productCost: data?.processing_orders_product_cost,
                shippingCost: data?.processing_orders_shipping_cost,
                icon: <CogIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Intransit Orders",
                value: data?.in_transit_orders,
                percentage: getPercenteage(data?.in_transit_orders, data?.total_orders),
                productCost: data?.in_transit_orders_product_cost,
                shippingCost: data?.in_transit_orders_shipping_cost,
                icon: <TruckIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Delivered Orders",
                value: data?.delivered_orders,
                percentage: getPercenteage(data?.delivered_orders, data?.confirmed_orders),
                productCost: data?.delivered_orders_product_cost,
                shippingCost: data?.delivered_orders_shipping_cost,
                icon: <DocumentCheckIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Returned Orders",
                value: data?.returned_orders,
                percentage: getPercenteage(data?.returned_orders, data?.confirmed_orders),
                productCost: data?.returned_orders_product_cost,
                shippingCost: data?.returned_orders_shipping_cost,
                icon: <ArrowUturnLeftIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Cancelled Orders",
                value: data?.cancelled_orders,
                percentage: getPercenteage(data?.cancelled_orders, data?.total_orders),
                productCost: data?.cancelled_orders_product_cost,
                shippingCost: data?.cancelled_orders_shipping_cost,
                icon: <XMarkIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              }
            ]
          );
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        },
      )
    },
    [navigate, currentTab, body]
  )

  const getProductsOrdersTopRegions = React.useCallback(
    () => {
      getRequest(
        currentTab === ordersBasedOnVendors[0].value ?
          getProductsOrdersTopRegionsUrl :
          getExternalProductsOrdersTopRegionsUrl,
        (data) => {
          setTopRegions(data);
          setIsLoadingTopRegions(false);
        },
        (error) => {
          setIsLoadingTopRegions(false);
        },
      )
    }, [currentTab])

  React.useEffect(() => {
    getProductsOrdersStatistics()
  }, [getProductsOrdersStatistics]);

  React.useEffect(() => {
    getProductsOrdersTopRegions()
  }, [getProductsOrdersTopRegions]);

  return (
    <Layout>
      <CustomPopOver
        id={popOver.id}
        anchorEl={popOver.anchorRef}
        open={popOver.open}
        onClose={popOver.handleClose}
        showDates={true}
        from={body.from}
        to={body.to}
        handleBodyChange={handleBodyChange}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 2,
          pb: 8
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              mb: 3,
            }}
          >
            <Typography sx={{ mr: "auto" }} variant="h5">Overview</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: 1,
                borderColor: '#bfbfbf'
              }}
            >
              <Button
                sx={{
                  color: "grey"
                }}
                variant='text'
                startIcon={
                  <SvgIcon fontSize='small'>
                    <CalendarIcon />
                  </SvgIcon>
                }
                endIcon={
                  <SvgIcon fontSize='small'>
                    <ChevronDownIcon />
                  </SvgIcon>
                }
                onClick={(event) => {
                  popOver.handleOpen(event)
                }}
              >
                {`${body.from.format('MMMM D, YYYY HH:mm:ss')} - `}
                {`${body.to.format('MMMM D, YYYY HH:mm:ss')}`}
              </Button>
            </Box>
          </Box>
          <CustomTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabsData={ordersBasedOnVendors}
            style={{}}
            indicatorColor={"primary"}
          />
          <Box sx={{ mb: 5 }} />
          {isLoading === true &&
            <Grid container spacing={2}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Skeleton variant='rectangular' height={130} />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Skeleton variant='rectangular' height={130} />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Skeleton variant='rectangular' height={130} />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Skeleton variant='rectangular' height={130} />
              </Grid>
            </Grid>
          }
          {!isLoading &&
            <Grid container spacing={2}>
              {dashboardData
                .map((data, index) => {

                  return (
                    <Grid key={index} item sm={12} xs={12} lg={4} md={4} xl={3}>
                      <ThemeCustomization>
                        <AnalyticEcommerce
                          title={data.name}
                          count={data.value}
                          amount={`${formatMoney(data.productCost)}, ${formatMoney(data.shippingCost)}`}
                          percentage={data.percentage}
                          color="warning"
                          isLoading={isLoading}
                        />
                      </ThemeCustomization>
                    </Grid>
                  )
                })
              }
            </Grid>
          }
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} lg={9} md={12} xl={9}>
              <TopRegionsBarChart
                topRegions={topRegions}
                isLoadingTopRegions={isLoadingTopRegions}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default Home